





import { Component, Vue } from 'vue-property-decorator'
import PagesComponent from '../components/Pages.vue'

@Component({
  components: {
    PagesComponent
  }
})
export default class Pages extends Vue {}
