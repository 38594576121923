<template>
<div class="pages" >

    <previewComponent :prevData="a4Data" :numPages="numberOfPagesRequired" :work_history1="work_history1" :work_history2="work_history2" :tidyProfile="tidyProfile" />
    <div class="print-box">
      <div class="print" ref="print" >
                  <div class="a4 m-0" id="a4" ref="a4" :class="theme">
            <PageHeader />
            <hr>
            <div class="content">
                <div v-if="a4Data.theme !== 'classic'" class="left-column">
                    <h2>Skills</h2>
                    <h3 class="main-skills">{{ a4Data.skill_labels[0] }}</h3>
                    <p> {{a4Data.skills.trim().replace(/^\s+|\s+$/g, '').replace(/,/g, '\n').trim() }}</p>
                    <h3>{{ a4Data.skill_labels[1] }}</h3>
                    <p>{{ a4Data.additionalSkills.trim().replace(/,/g, '\n').replace(/^\s+|\s+$/g, '').trim() }}</p>
                    <template v-if="a4Data.qualifications.length">
                    <h3>Qualifications </h3>
                    <p>{{ a4Data.qualifications.replace(/^\s+|\s+$/g, '').replace(/,/g, '\n').trim() }}</p>
                    </template>
                    <template v-if="a4Data.hobbies.length">
                    <h3>hobbies </h3>
                    <p>{{ a4Data.hobbies.replace(/,/g, '\n') }}</p>
                    </template>
                </div>
                <div class="main">
                    <h2>profile summary</h2>
                    <div ref="profile_content" class="profile">
                        <p v-for="line of tidyProfile" :key="line">{{ line }}</p>
                    </div>

                      <div v-if="a4Data.theme == 'classic'" class="left-column" ref="classic">
                    <h2>Skills</h2>
                    <h3 class="main-skills">{{ a4Data.skill_labels[0] }}</h3>
                    <p class="skill-list"> {{a4Data.skills.trim().replace(/^\s+|\s+$/g, '').replace(/,/g, '&nbsp;&bull;&nbsp;').trim() }}</p>
                    <h3>{{ a4Data.skill_labels[1] }}</h3>
                    <p class="skill-list">{{ a4Data.additionalSkills.trim().replace(/^\s+|\s+$/g, '').replace(/,/g, '&nbsp;&bull;&nbsp;').trim() }}</p>
                    <div class="qualifications-hobbies">
                    <template v-if="a4Data.qualifications.length">
                      <div>
                        <h3>Qualifications </h3>
                        <p>{{ a4Data.qualifications.replace(/, /g, ',').replace(/,/g, '\n') }}</p>
                      </div>
                    </template>
                    <template v-if="a4Data.hobbies.length">
                      <div>
                        <h3>hobbies </h3>
                        <p>{{ a4Data.hobbies.replace(/,/g, '\n') }}</p>
                      </div>
                    </template>
                    </div>
                </div>

                    <template v-if="numberOfPagesRequired === 1 && a4Data.work_history[0].Description">
                        <h2 class="exp-heading">experience</h2>
                        <div class="individual-job" v-for="job in a4Data.work_history" v-bind:key="'j'+ job.id">
                          <template v-if="job">
                            <p> <b> {{ job.Dates }} - {{ job.Company }} </b></p>
                            <p>{{ job.Role }}</p>
                            <p> <i>{{ job.Technologies }}</i></p>
                            <p v-if="job.Description !== undefined" v-html="`<ul><li>${job.Description.split( '\n' ).join( '</li><li>' )}</li></ul>`"> </p>
                            <p v-else>{{ job.Description }}</p>
                            <hr>
                            </template>
                        </div>
                    </template>
                </div>
            </div>
        </div>

                <div class="a4" ref="a42" v-if="numberOfPagesRequired > 1  && a4Data.work_history[0].Description" :class="theme" >
            <PageHeader />
            <hr>
            <div class="w-100 p-3">
                <div class="main">
                    <h2 class="exp-heading">experience</h2>
                    <job class="individual-job" :jobs="work_history1" :tableStyle="true" />
                     <template v-if="a4Data.unrelated.length > 2 && numberOfPagesRequired === 2">
                    <h3 class="unrelated-heading">unrelated experience</h3>
                    <div class="individual-job">

                         <p v-html="`<ul class='list-disc p-2 pl-6'><li>${a4Data.unrelated.split( '\n' ).join( '</li><li>' )}</li></ul>`"> </p>
                        <hr>
                        <br>
                    </div>
                    </template>
                </div>
            </div>
        </div>
        <div class="a4 a43" ref="a43" v-if="numberOfPagesRequired > 2"  :class="theme">
            <PageHeader />
            <hr>
            <div class="w-100 p-3">
                <div class="main">
                    <h2 class="exp-heading">experience</h2>
                    <job class="individual-job" :jobs="work_history2" :tableStyle="true" />
                    <template v-if="a4Data.unrelated.length > 2 && numberOfPagesRequired === 3">
                    <h3 class="unrelated-heading">unrelated experience</h3>
                    <div class="individual-job">

                         <p v-html="`<ul class='list-disc p-2 pl-6'><li>${a4Data.unrelated.split( '\n' ).join( '</li><li>' )}</li></ul>`"> </p>
                        <hr>
                        <br>
                    </div>
                    </template>
                </div>
            </div>
        </div>
      </div>
    </div>
</div>
</template>

<script lang="js">
import { Component, Vue } from 'vue-property-decorator'
import PageHeader from './PageHeader.vue'
import PreviewComponent from '@/components/Preview.vue'
import Job from '@/components/Job.vue'

@Component({
  name: 'pagesComponent',
  components: {
    PageHeader,
    Job,
    PreviewComponent
  },
  props: [
    'msg'
  ],
  data () {
    return {
      numberOfPagesRequired: 1,
      expand0: false,
      expand1: false,
      expand2: false,
      overideJob: 0,
      workFontSize: 2
    }
  },
  methods: {
    countOccurances (val, item) {
      const reg = new RegExp(item, 'g')
      return val.length - val.replace(reg, '').length
    },
    determinePages: function () {
      if (this.a4Data.profile.length < 3) {
        return 0
      }
      // page maths
      if ((this.pageHeight / 2) < this.profileHeight) {
        this.numberOfPagesRequired = 2
      }
      if (this.a4Data.work_history.length > 3) {
        this.numberOfPagesRequired++
      }
    },
    getLast (parent, term) {
      return parent.querySelectorAll(term).length
    },
    react () {
      if (document.getElementsByClassName('a4')[1]) {
        if (document.getElementsByClassName('a4').length > 1) {
          const a42 = document.getElementsByClassName('a4')[1]
          const indjobs = a42.getElementsByClassName('individual-job')
          if (indjobs[indjobs.length - 1] !== null) {
            this.overideJob = a42.clientHeight + 50 > (indjobs[indjobs.length - 1].offsetTop + indjobs[indjobs.length - 1].clientHeight) ? 0 : -1
          }
        }
        return 0
      }
      return 0
    },
    clonePages: async function () {
      const previews = document.querySelectorAll('.a4p')
      if (previews) {
        await document.querySelectorAll('.a4').forEach(
          (el, index) => {
            const clone = el.cloneNode(['deep'])

            clone.style.display = 'block'

            previews[index].children.forEach(element => {
              previews[index].removeChild(element)
            })
            previews[index].appendChild(clone)
          }
        )
      }
    }
  },
  computed: {
    a4Data () { return this.$store.state },
    pageHeight () { return this.$refs.a4 !== undefined ? this.$refs.a4.clientHeight : 0 },
    profileHeight () {
      return this.$refs.profile_content
        ? this.theme !== 'classic'
          ? this.$refs.profile_content.clientHeight
          : this.$refs.profile_content.clientHeight + this.$refs.classic.clientHeight
        : null
    },
    tidyProfile () { return this.$store.state.profile.split('\n') },
    jobCount () { return this.$store.state.work_history.length },
    workLength () { return this.$store.state.work_history.map(r => r.Description).join().length },
    work_history1 () { return this.workLength > 2000 ? this.$store.state.work_history.slice(0, Math.ceil(this.jobCount / 2) + this.overideJob) : this.$store.state.work_history },
    work_history2 () { return this.workLength > 2000 ? this.$store.state.work_history.slice(Math.ceil(this.jobCount / 2) + this.overideJob) : 0 },
    workFontStyle () { return { padding: `${this.workFontSize}rem` } },
    theme: { get: function () { return this.$store.state.theme } }
  },
  mounted: function () {
    this.determinePages()
    if (this.$store.state.profile.length < 5) {
      this.$router.push('home')
    }
    setTimeout(() => {
      this.react()
      // this.clonePages()
    }, 1500)
  }
})
export default class PagesComponent extends Vue {
}
</script>

<style lang="scss" scoped>
@import '@/styles/classic.scss';
@import '@/styles/modern.scss';
@import '@/styles/lite.scss';

select {
    text-transform: capitalize;
}

option {
  color: white;
}
.print {
  position: relative;
    margin: 0;
    padding: 0;
    display: grid;
    grid-template-columns: 1fr;
    ul{
      list-style-position: inside !important;
    }
    .a4 {
      position: relative !important;
      margin: 0;
      left: 0px;
      transform: translate(0);
    }
  }
.a4 {
    transition: .2s;
    background-color: white;
    margin: 0;
    overflow: hidden;
    max-height: 297mm;
    height: 297mm;
    min-width: 210mm;
    width: 210mm;
    font-size: 100% !important;
    z-index: 10;
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    * {
      pointer-events: none;
    }
}

  .individual-job {
      text-align: left;
      font-size: 11px;
      line-height: 1;
  }
  .a43 {
    max-height: 295mm;
}

  .expand {
    min-width: 210mm;
    max-width: 210mm;
    transition: 0.01s;
    margin: 0px;
    padding: 0;
    transform: scale(1);
  }
  .expandPrev {
    min-width: 210mm;
    max-width: 210mm;
    transition: 0.01s;
    margin: 0px;
    padding: 0;
    transform: scale(1);
    z-index: 10000;
    position: relative;
  }
  .print-box {
      position: absolute;
      background-color: #ddd;
      margin: 0;
      padding: 0;
      top: -99999px;
  }

  @media screen and (max-width: 1100px) {
    .page-control {
      width: 100%;
      h1 {
        font-size: calc(14px + 1vw);
      }
      button {
        width: calc(100px + 1vw)
      }
    }
    .a4p {
      transform: scale(.5);
      left: 50%;
      position: absolute;
    }
  }

    @media screen and (max-width: 350px) {
    .a4p {
      transform: scale(.35);
      left: 50%;
      position: absolute;
    }
    }
</style>
