













import {
  Component,
  Vue
} from 'vue-property-decorator'

@Component({
  name: 'Job',
  props: {
    jobs: [],
    tableStyle: Boolean
  }
})
export default class Job extends Vue {}
