<template>
<div class="preview" >
  <div class="page-control">
  <button @click="showPage > 0 ? showPage-- : showPage = numPages -1">Previous</button>
  <h1 class="pageNum">Page {{showPage + 1}} of {{ numPages }}</h1>
  <button @click="showPage < numPages -1 ? showPage++ : showPage = 0">Next</button>
  </div>
    <div class="pt-3 mx-auto grid mobile" v-if="prevData.profile.length > 2" >
      <div class="mx-auto grid">
        <div class="a4 m-0" id="a4p" ref="a4p" :class="theme" v-show="showPage === 0" :style="{ transform : `scale(${scaleCalc})`}">
            <PageHeader />
            <hr>
            <div class="content">
                <div v-if="prevData.theme !== 'classic'" class="left-column">
                    <h2>Skills</h2>
                    <h3 class="main-skills">{{ prevData.skill_labels[0] }}</h3>
                    <p> {{prevData.skills.trim().replace(/^\s+|\s+$/g, '').replace(/,/g, '\n').trim() }}</p>
                    <h3>{{ prevData.skill_labels[1] }}</h3>
                    <p>{{ prevData.additionalSkills.trim().replace(/,/g, '\n').replace(/^\s+|\s+$/g, '').trim() }}</p>
                    <template v-if="prevData.qualifications.length">
                    <h3>Qualifications </h3>
                    <p>{{ prevData.qualifications.replace(/^\s+|\s+$/g, '').replace(/,/g, '\n') }}</p>
                    </template>
                    <template v-if="prevData.hobbies.length">
                    <h3>hobbies </h3>
                    <p>{{ prevData.hobbies.replace(/,/g, '\n') }}</p>
                    </template>
                </div>
                <div class="main">
                    <h2>profile summary</h2>
                    <div ref="profile_content" class="profile">
                        <p v-for="line of tidyProfile" :key="line">{{ line }}</p>
                    </div>

                      <div v-if="prevData.theme == 'classic'" class="left-column" ref="classic">
                    <h2>Skills</h2>
                    <h3 class="main-skills">{{ prevData.skill_labels[0] }}</h3>
                    <p class="skill-list"> {{prevData.skills.trim().replace(/^\s+|\s+$/g, '').replace(/,/g, '&nbsp;&bull;&nbsp;').trim() }}</p>
                    <h3>{{ prevData.skill_labels[1] }}</h3>
                    <p class="skill-list">{{ prevData.additionalSkills.trim().replace(/^\s+|\s+$/g, '').replace(/,/g, '&nbsp;&bull;&nbsp;').trim() }}</p>
                    <div class="qualifications-hobbies">
                    <template v-if="prevData.qualifications.length">
                      <div>
                        <h3>Qualifications </h3>
                        <p>{{ prevData.qualifications.replace(/, /g, ',').replace(/,/g, '\n') }}</p>
                      </div>
                    </template>
                    <template v-if="prevData.hobbies.length">
                      <div>
                        <h3>hobbies </h3>
                        <p>{{ prevData.hobbies.replace(/,/g, '\n') }}</p>
                      </div>
                    </template>
                    </div>
                </div>

                    <template v-if="numPages === 1 && prevData.work_history[0].Description">
                        <h2 class="exp-heading">experience</h2>
                        <div class="individual-job" v-for="job in prevData.work_history" v-bind:key="'j'+ job.id">
                          <template v-if="job">
                            <p> <b> {{ job.Dates }} - {{ job.Company }} </b></p>
                            <p>{{ job.Role }}</p>
                            <p> <i>{{ job.Technologies }}</i></p>
                            <p v-if="job.Description !== undefined" v-html="`<ul><li>${job.Description.split( '\n' ).join( '</li><li>' )}</li></ul>`"> </p>
                            <p v-else>{{ job.Description }}</p>
                            <hr>
                            </template>
                        </div>
                    </template>
                </div>
            </div>
        </div>
      </div>
      <div class="w-full ">
                <div class="a4" ref="a42"
                v-if="numPages > 1  && prevData.work_history[0].Description"
                  :class="theme"
                  v-show="showPage === 1"
                  :style="{ transform
                  : `scale(${scaleCalc})`}">
            <PageHeader />
            <hr>
            <div class="w-100 p-3">
                <div class="main">
                    <h2 class="exp-heading">experience</h2>
                    <job class="individual-job" :jobs="work_history1" :tableStyle="true" />
                     <template v-if="prevData.unrelated.length > 2 && numPages === 2">
                    <h3 class="unrelated-heading">unrelated experience</h3>
                    <div class="individual-job">

                         <p v-html="`<ul class='list-disc p-2 pl-6'><li>${prevData.unrelated.split( '\n' ).join( '</li><li>' )}</li></ul>`"> </p>
                        <hr>
                        <br>
                    </div>
                    </template>
                </div>
            </div>
                </div>
      </div>
        <div class="w-full ">
        <div class="a4 a43" ref="a43" v-if="numPages > 2" :class="theme"  v-show="showPage === 2" :style="{ transform : `scale(${scaleCalc})`}">
            <PageHeader />
            <hr>
            <div class="w-100 p-3">
                <div class="main">
                    <h2 class="exp-heading">experience</h2>
                    <job class="individual-job" :jobs="work_history2" :tableStyle="true" />
                    <template v-if="prevData.unrelated.length > 2 && numPages === 3">
                    <h3 class="unrelated-heading">unrelated experience</h3>
                    <div class="individual-job">

                         <p v-html="`<ul class='list-disc p-2 pl-6'><li>${prevData.unrelated.split( '\n' ).join( '</li><li>' )}</li></ul>`"> </p>
                        <hr>
                        <br>
                    </div>
                    </template>
                </div>
            </div>
        </div>
    </div>
    </div>
    <div v-else>
        <h2 class="no-data">Please fill out create page</h2>
    </div>
</div>
</template>

<script lang="js">
import { Component, Vue } from 'vue-property-decorator'
import PageHeader from './PageHeader.vue'
import Job from '@/components/Job.vue'

@Component({
  name: 'previewComponent',
  components: {
    PageHeader,
    Job
  },
  props: [
    'prevData',
    'numPages',
    'work_history1',
    'work_history2',
    'tidyProfile'
  ],
  data () {
    return {
      showPage: 0,
      windowWidth: window.innerWidth
    }
  },
  mounted: function () {
    window.addEventListener('resize', this.onResize)
  },
  computed: {
    theme: function () { return this.prevData.theme },
    scaleCalc: function () {
      return this.windowWidth < 794 ? this.windowWidth / 794 : 1
    }
  },
  methods: {
    onResize: function () {
      this.windowWidth = window.innerWidth
    }
  },
  beforeDestroy: function () {
    window.removeEventListener('resize', this.onResize)
  }
})
export default class PreviewComponent extends Vue {
}
</script>

<style lang="scss">
@import '@/styles/classic.scss';
@import '@/styles/modern.scss';
@import '@/styles/lite.scss';

.preview {
  margin: auto;
}
.page-control {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    text-align: justify;
    width: 1000px;
    margin: auto;
    * {
      text-align: center;
      margin: auto;
    }
    h1 {
      font-size: 32px;
      color:  rgba(31, 41, 55, .95);
    }
    button {
      border: 1px solid gray;
      width: 140px;
      padding: 1rem;
      border-radius: 10px;
      background-color:  rgba(31, 41, 55, .95);
      color: white;
    }
}

select {
    text-transform: capitalize;
}

option {
  color: white;
}
.print {
  position: relative;
    margin: 0;
    padding: 0;
    display: grid;
    grid-template-columns: 1fr;
    ul{
      list-style-position: inside !important;
    }

  }
.a4 {
    transition: .2s;
    background-color: white;
    margin: 0;
    overflow: hidden;
    max-height: 297mm;
    height: 297mm;
    min-width: 210mm;
    width: 210mm;
    font-size: 100% !important;
    z-index: 10;
    // position: absolute;
    // left: 50%;
    // top: 70%;
    // transform: translate(-50%, -50%);
    transform-origin: top center;
    margin: auto !important;
    align-content: center;
    align-items: center;
    align-self: center;
    * {
      pointer-events: none;
    }
}

  .individual-job {
      text-align: left;
      font-size: 11px;
      line-height: 1;
  }
  .a43 {
    max-height: 295mm;
}

  @media screen and (max-width: 1100px) {
    .page-control {
      width: 100%;
      h1 {
        font-size: calc(14px + 1vw);
      }
      button {
        width: calc(100px + 1vw)
      }
    }
  }

    @media screen and (max-width: 794px) {
    .pages {
      padding: 1rem 0 !important;
      max-height: 700px !important;
    }
    .mobile, .a4 {
      max-width: 100%;
      padding: 2rem 0;
      margin: 0;
      transform-origin: top left;
    }
    }
</style>
