
























import { Component, Vue } from 'vue-property-decorator'

@Component({
  name: 'PageHeader',
  computed: {
    a4Data () { return this.$store.state }
  }
})
export default class PageHeader extends Vue {}
